var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "flex flex-col gap-4" }, [
          _c("div", { staticClass: "flex flex-col gap-2" }, [
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:191",
                      expression: "'required|max:191'",
                    },
                  ],
                  staticClass: "w-full required",
                  attrs: { id: "module_name", label: _vm.$t("module.name") },
                  model: {
                    value: _vm.model.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("name"),
                        expression: "errors.has('name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("name")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-textarea", {
                  attrs: {
                    id: "module_description",
                    label: _vm.$t("module.description"),
                  },
                  model: {
                    value: _vm.model.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "description", $$v)
                    },
                    expression: "model.description",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("description"),
                        expression: "errors.has('description')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("description")))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end" },
            [
              !_vm.isEdit()
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "modules.create",
                          expression: "'modules.create'",
                        },
                      ],
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.createOrUpdate },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                  )
                : _vm._e(),
              _vm.isEdit()
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "modules.edit",
                          expression: "'modules.edit'",
                        },
                      ],
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.createOrUpdate },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                  )
                : _vm._e(),
              _c(
                "vs-button",
                { attrs: { type: "border" }, on: { click: _vm.cancel } },
                [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }